import { ProductsGrid } from "@components/global";
import CategoryPanelPreview from "@components/global/categoryPanelPreview";
import ColumnContent from "@components/global/columnContent";
import CtaBlock from "@components/global/ctaBlock";
import Hero from "@components/global/hero";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import RecipePreview from "@components/global/recipePreview";
import { Query } from "@graphql-types";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import { useStore } from "@state/store";
import { isBrowser } from "@util/helper";
import { Container } from "@util/standard";
import {
  isSanityLearnCategoryPreview,
  isSanityColumnContent,
  isSanityCtaBlock,
  isSanityRecipePreview,
  isSanityCategoryPanelPreview,
} from "@util/types";
import { PageProps, graphql } from "gatsby";
import React, { useEffect } from "react";

interface Props extends PageProps {
  data: Query;
}

function Recipes({ data }: Props) {
  if (data == null || data.sanityShop == null) {
    return null;
  }

  const { hero, pageContent, featuredProduct, seo } = data.sanityShop;
  useEffect(() => {
    useStore.setState({ searchFilters: [] });
  });

  useEffect(() => {
    if (isBrowser()) {
      window.scrollTo({ top: 0 });
    }
  }, []);

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        {hero && <Hero data={hero} />}
        {featuredProduct && (
          <Container margin="15px 0 0 0">
            <ColumnContent data={featuredProduct} />
          </Container>
        )}
        <Container flexDirection="column" width="65%" margin="80px auto" mobileWidth="90%">
          <ProductsGrid />
        </Container>
        {pageContent &&
          pageContent.map(data => {
            if (isSanityLearnCategoryPreview(data)) {
              return <LearnCategoryPreview data={data} key={data._key} />;
            }
            if (isSanityColumnContent(data)) {
              return <ColumnContent data={data} key={data._key} />;
            }
            if (isSanityCtaBlock(data)) {
              return <CtaBlock data={data} key={data._key} />;
            }
            if (isSanityCategoryPanelPreview(data)) {
              return <CategoryPanelPreview data={data} key={data._key} />;
            }
            if (isSanityRecipePreview(data)) {
              return <RecipePreview data={data} key={data._key} />;
            }
            return null;
          })}
      </Layout>
    </>
  );
}

export default Recipes;

export const query = graphql`
  {
    sanityShop {
      hero {
        ...sanityHero
      }
      seo {
        ...sanitySeo
      }
      featuredProduct {
        ...sanityColumnContent
      }
      pageContent {
        ... on SanityLearnCategoryPreview {
          ...sanityLearnCategoryPreview
        }
        ... on SanityCtaBlock {
          ...sanityCtaBlock
        }
        ... on SanityColumnContent {
          ...sanityColumnContent
        }
        ... on SanityRecipePreview {
          ...sanityRecipePreview
        }
        ... on SanityCategoryPanelPreview {
          ...sanityCategoryPanelPreview
        }
      }
    }
  }
`;
